import React from "react";
import { Route } from 'react-router-dom'
import AppLayout from "../layout/AppLayout";

type AppRouteProps = {
    Component: React.ComponentType,
    exact?: boolean,
    path: string
}

const AppRoute = ({
    Component,
    exact,
    path,
    ...rest
} : AppRouteProps) => {
    return (
        <Route
        {...rest}
        path={path}
        exact
        render={props => {
            return (
            <AppLayout history={props.history} >
                <Component />
            </AppLayout>
            )
        }}
        
        />
            
       
    )
}

export default AppRoute