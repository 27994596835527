import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {publicRoutes} from './routes'
import AppRoute from './routes/route';

type RouteProps = {
  exact?: boolean,
  component: React.ComponentType,
  path: string

}

const App = () => {
  return (
    <Router>
      <Switch>
        {publicRoutes.map((route: RouteProps, index) => (
          <AppRoute path={route.path} exact={route.exact} key={index} Component={route.component} />
        ))}
      </Switch>
    </Router>
  );
}

export default App;
