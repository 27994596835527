import React from "react";
import styled from 'styled-components'
type LayoutProps = {
    children: React.ReactNode,
    history: object
}

const AppContainer = styled.div`
    padding: 0px;
    overflow: hidden;
`

const AppLayout = (props: LayoutProps) => {
    const {children} = props
    return (
        <div>
            <AppContainer>
                {children}
            </AppContainer>
        </div>
    )
}

export default AppLayout