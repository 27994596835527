import React from 'react'
import { useHistory } from "react-router-dom";
import LogoSelleri from '../../assets/images/selleri.png'
import GplayBtn from '../../assets/images/gplaybtn.png'
import InviteCode from '../../assets/images/kode-undangan.png'
import styled from 'styled-components'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { SELLERI_PLAYSTORE } from '../../constant/url'
import { theme } from '../../config/theme';

type ButtonProps = {
    fullwidth?: boolean
}

const LogoContainer = styled.div`
padding-top: 0px;
padding-bottom:0px;
display: flex;
@media(min-width: 768px) {
    display: none
}
`
const MainContainer = styled.div`
margin-left: auto;
margin-right: auto;
min-height: 100vh;
@media(min-width: 768px) {
    margin-top: 100px;
    max-width: 1280px;
  
};
`
const ImageLogo = styled.img`
    width: 150px;
    height: auto;
    margin: auto
`
const ParentContainer = styled.div`
@media(min-width: 768px) {
    display: flex
}
`
const DescriptionTextContainer = styled.div`
    margin-top: 20px;
    padding: 1rem 2rem;
    @media(min-width: 768px) {
        width: 50%;
        margin-top: 0px;
        padding: 0rem 1rem;
    }
`

const ImageLogoDesktop = styled.img`
width: 150px;
@media(max-width: 767px) {
    display: none;
};
`

const CustomParagraph = styled.p`
font-weight: 300;
font-size: 14px
`

const LinkPlaystore = styled.a`
    color: 
`

const BoxDot = styled.div`
    background-color: ${theme.main50};
    padding: 12px;
    border: 1px dashed ${theme.main};
    display: flex;
    align-items: center
`

const SuccessCopyText = styled.p`
font-size: 12px;
color: ${theme.main};
background-color: ${theme.main50};
font-weight: 400;
text-align: center;
margin-bottom: 0px;
margin-left: 5px
`

const ButtonPlaystoreContainerDesktop = styled.div`
@media(max-width: 767px) {
    display: none;
};
`
export const CustomButton = styled.button`
width: ${(props: ButtonProps) => props.fullwidth ? '100%' : 'auto'} ;
display: flex;
align-items: center;
justify-content: center;
color: ${theme.black};
border: 1px solid ${theme.border};
padding: 10px;
background-color: white;
border-radius: 3px;
font-weight: bold
`

export const GplayImage = styled.img`
height: 30px;
margin-right: 10px
`

const CopyIconContainer = styled.div`
margin-left: auto;
text-align: center
`

const CodeText = styled.h3`
font-weight: bold;
margin-bottom: 0px
`

const BgReferal = styled.div`
background: url(${InviteCode});
background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(min-width: 768px) {
        width: 50%;
        background-size: contain;
        padding-top: 0%;
        height: 400px;
        background-position: center;
    };

`

const HeadingCode = styled.h1`
padding-bottom: 70%;
font-weight: bold;
color: ${(props) => props.color ? props.color : theme.black};
margin-bottom: 0px;
@media(min-width: 768px) {
    
    padding-bottom: 0%;
    font-size: 2rem;
    margin-top: 100px

};
`

const ButtonPlaystoreContainer = styled.div`
@media(min-width: 768px) {
    display: none
};
`

const IconCopyContainer = styled.div`
display: flex;
align-items: center;
justify-content: center
`


const MainPage = () => {
    const history = useHistory()
    const [code, setCode] = React.useState('')
    const [successCopy, setSuccessCopy] = React.useState(false)
    const handleCopyClipboard = () => {
        setSuccessCopy(true)
        setTimeout(() => {
            setSuccessCopy(false)
        }, 1000)
    }

    const openPlaystore = () => {
        window.open(SELLERI_PLAYSTORE, '_blank')
    }

    React.useEffect(() => {
        if(history.location.pathname && typeof history.location.pathname === 'string') {
            const replace = history.location.pathname.replace('/', '')
            setCode(replace)
        }
    }, [])

    return (
       <MainContainer>
            <LogoContainer  >
                <ImageLogo src={LogoSelleri} />
            </LogoContainer>
            <ParentContainer>
            <DescriptionTextContainer>
                <ImageLogoDesktop src={LogoSelleri} />
                <CustomParagraph>
                Jualan tanpa modal dan peluang mendapatkan penghasilan mudah hanya dari rumah dengan bergabung di Selleri. Klik link ini dan hasilkan uang bersama saya! <LinkPlaystore href={SELLERI_PLAYSTORE} target="_blank" rel="noopener noreferrer" >Link playstore</LinkPlaystore> 
                </CustomParagraph>
                <div className="mt-3" >
                <CustomParagraph>
                Dan isi kode undangan saya setelah mendaftar:
                </CustomParagraph>
                <CopyToClipboard onCopy={handleCopyClipboard} text={code} >
                <BoxDot className="mt-2" >
                    <CodeText  >
                    {code}
                    </CodeText>
                    <CopyIconContainer >
                    <IconCopyContainer  >
                    <FontAwesomeIcon className='fa-2x' icon={faCopy} color={theme.main} />
                    {successCopy ?<SuccessCopyText>
                    Copied
                </SuccessCopyText> : null}
                    </IconCopyContainer>
                   
                    </CopyIconContainer>
                </BoxDot>
                </CopyToClipboard>
                <ButtonPlaystoreContainerDesktop className="mt-3"  >
                       <CustomButton onClick={openPlaystore} >
                           <GplayImage src={GplayBtn} /> 
                           Download Aplikasi Selleri
                       </CustomButton>
            </ButtonPlaystoreContainerDesktop>
                        
                </div>
              
            </DescriptionTextContainer>
            <BgReferal>
                    <HeadingCode color={theme.main} >
                        {code}
                    </HeadingCode>
                </BgReferal>
                <ButtonPlaystoreContainer className="p-2" >
                       <CustomButton fullwidth onClick={openPlaystore} >
                           <GplayImage src={GplayBtn} /> 
                           Download Aplikasi Selleri
                       </CustomButton>
            </ButtonPlaystoreContainer>
            </ParentContainer>
       </MainContainer>
    )
}


export default MainPage