import React from "react";
import styled from 'styled-components'
import LogoSelleri from '../../assets/images/selleri.png'
import Logo2 from '../../assets/images/logo-selleri.png'
import NotFoundImage from '../../assets/images/notfound.png'
import ReferralNotFound from '../../assets/images/referral-notfound.png'
import {CustomButton, GplayImage} from '../Main'
import GplayBtn from '../../assets/images/gplaybtn.png'
import { BENEFIT_DATA } from "./constant";
import { SELLERI_PLAYSTORE, SELLERI_WEB } from '../../constant/url'

const LogoContainer = styled.div`
padding-top: 0px;
padding-bottom:0px;
display: flex;
@media(min-width: 768px) {
    display: none
}
`
const MainContainer = styled.div`
margin-left: auto;
margin-right: auto;
min-height: 100vh;
@media(min-width: 768px) {
    margin-top: 100px;
    max-width: 1280px;

  
};
`
const ImageLogo = styled.img`
    width: 150px;
    height: auto;
    margin: auto
`
const ParentContainer = styled.div`
@media(min-width: 768px) {
    display: flex
}
`
const DescriptionTextContainer = styled.div`
    margin-top: 20px;
    padding: 1rem 2rem;
    @media(min-width: 768px) {
        width: 50%;
        margin-top: 0px;
        padding: 0rem 1rem;
    }
`

const ImageLogoDesktop = styled.img`
width: 150px;
@media(max-width: 767px) {
    display: none;
};
`

const TitleText = styled.p`
font-weight: 300;
font-size: 18px;
font-weight: 700;
text-align: center;
@media(min-width: 768px) {
    text-align: left
}
`

const SubtitleText = styled.p`
font-weight: 300;
font-size: 14px;
text-align: center;
@media(min-width: 768px) {
    text-align: left
}
`


const BgNotfound = styled.div`
background: url(${NotFoundImage});
background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(min-width: 768px) {
        width: 50%;
        background-size: contain;
        padding-top: 0%;
        height: 400px;
        background-position: center;
    };

`
const ImageNotfoundDesktop = styled(ImageLogoDesktop)`
    width: 200px
`

const SelleriRightContainer = styled.div`
margin-right: auto;
margin-left: auto
`
const SelleriLogoRightContainer = styled.div`
width: 115px;
height: 115px;
box-shadow: 0 1px 6px 0 rgb(49 53 59 / 12%);
border-radius: 10px;
margin-right: auto;
margin-left: auto
`
const LogoSelleriImage = styled.img`
width:100%
`
const CenterCustomButton = styled(CustomButton)`
margin-right: auto;
margin-left: auto;
margin-top: 30px
`

const SeparatorText = styled(SubtitleText)`
color: #979797;
text-align:center;
margin-bottom: 0px;
background-color: white;
padding: 0px 10px
`

const SeparatorContainer = styled.div`
text-align: center;
margin: 56px 20px;
position: relative;
&:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid #c9c6c6    ;
    background: black;
    width: 100%;
    transform: translateY(-50%);
}
`

const SeparatorLink = styled.div`
padding: 0 10px;
  display: flex;
  justify-content: center;
  position: relative;
`

const BigText = styled.h3`
font-size:18px;
color: #4a4a4a;
text-align: center;
font-weight: bold

`

const ContainerBenefit = styled.div`
    padding: 15px;
    margin-left:auto;
    margin-right: auto;
    border: 1px solid #c9c6c6;
    margin-top: 60px;
    border-radius: 10px
`

const BenefitContainer = styled.div`
    padding: 20px 0px;
    display: flex;
    align-items:center
`
const BenefitImage = styled.img`
width: 68px;
margin-right: 10px;
`
const SubtitleTextList = styled.p`
font-weight: 300;
font-size: 14px;
text-align: left;

`


const NotFound = () => {

    const openLink = (link:string) => {
        window.open(link, '_blank')
    }

    return (
        <MainContainer>
        <LogoContainer  >
            <ImageLogo src={LogoSelleri} />
        </LogoContainer>
        <LogoContainer>
            <ImageLogo src={ReferralNotFound} />
        </LogoContainer>
        <ParentContainer>
        <DescriptionTextContainer>
            <ImageLogoDesktop src={LogoSelleri} />
            <div>
            <ImageNotfoundDesktop src={ReferralNotFound} />

            </div>
            <TitleText className="mt-3" >
            Referral tidak di temukan
            </TitleText>
            <div className="mt-3" >
            <SubtitleText className="mt-3" >
            Maaf kode referralmu tidak dapat ditemukan, coba cek lagi kode referralmu
            </SubtitleText>
                    
            </div>
          
        </DescriptionTextContainer>
        
        <SelleriRightContainer>
            <SelleriLogoRightContainer>
            <LogoSelleriImage src={Logo2} />
            </SelleriLogoRightContainer>
            <div>
                <CenterCustomButton onClick={() => openLink(SELLERI_PLAYSTORE)}  >
                    <GplayImage src={GplayBtn} />
                    Download Aplikasi Selleri
                </CenterCustomButton>
            </div>
            <SeparatorContainer>
                <SeparatorLink>
                <SeparatorText  >
                    atau kamu bisa
                </SeparatorText>
                </SeparatorLink>
                
            </SeparatorContainer>
            <div className="p-1" >
                <BigText>
                Kunjungi Website kami untuk informasi lainnya
                </BigText>
            </div>
            <div>
                <CenterCustomButton onClick={() => openLink(SELLERI_WEB)}  >
                    <GplayImage src={Logo2} />
                    Kunjungi Website Selleri
                </CenterCustomButton>
            </div>
            <div className="p-3" >
            <ContainerBenefit>
                <TitleText>
                    Di Selleri kamu bisa :
                </TitleText>
                {BENEFIT_DATA.map((benefit, index) => (
                    <BenefitContainer key={index} >
                        <BenefitImage src={benefit.image} />
                        <SubtitleTextList>
                            {index + 1}. {benefit.benefit}
                        </SubtitleTextList>
                    </BenefitContainer>
                ))}
            </ContainerBenefit>
            </div>
            
        </SelleriRightContainer>
        {/* <BgNotfound/> */}
        </ParentContainer>
   </MainContainer>
    )
}


export default NotFound